.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.mh-150{
  max-height: 100px;
}

.logo-nav {
  min-width: 25px;
  background-image: url("assets/logo-white-nav.png") ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

}

.logo{
  background: url("assets/logo-white.png") no-repeat center;
  min-width: 100px;
}

.router-link-exact-active{
  border-bottom-color: #a6133c !important;
}

.color-red{
  color:#a6133c !important;
}
.color-white{
  color:white !important;
}



.bg-image {
  background-image: url("assets/home_image.jpeg");
  height: 75vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-red{
  background: #a6133c !important;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.title-container {
  padding-left: 0;
  padding-right: 0;
}

.color-black{
  color: black;
}

.w-35{
  width: 35% !important;
}

footer .logo{
  margin-bottom: 0 !important;
}

footer {
  padding: 17px!important;
  min-height: 180px;
  max-width: 100vw;
}

.size-xsm {
  font-size: x-small;
}

@media (min-width: 768px) {
  .size-xsm {
    font-size: small;
  }
}

.container:has(#home){
  padding: 0 !important;
  margin: 0 !important;
}